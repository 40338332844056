import React from "react";
import "./App.css";
import { ReactComponent as Logo } from "./assets/logo.svg";
import { Input, NotFound, Success } from "./components";
import { useJwt } from "react-jwt";

interface IUserInfo {
  firstName: string;
  lastName: string;
  userId: string;
}

function App() {
  const { decodedToken } = useJwt<IUserInfo>(window.location.pathname);

  const [userInfo, setUserInfo] = React.useState({ name: "", userId: "" });
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    if (!decodedToken) return;

    setUserInfo({
      name: `${decodedToken.firstName} ${decodedToken.lastName}`,
      userId: decodedToken.userId,
    });
  }, [decodedToken]);

  const handleInvitationSucceeded = () => {
    setSuccess(true);
  };

  return (
    <div className="App">
      <Logo />
      {!success &&
        (userInfo.userId ? (
          <Input info={userInfo} handleSuccess={handleInvitationSucceeded} />
        ) : (
          <NotFound />
        ))}
      {success && <Success />}
    </div>
  );
}

export default App;
