import React from "react";
import "./styles.css";
import { ReactComponent as ThumbsUp } from "../../assets/ThumbsUp.svg";
import GoogleBadge from "../../assets/google-play-badge.png";
import { ReactComponent as AppleBadge } from "../../assets/app-badge.svg";

export const Success = () => {
  return (
    <div className="success">
      <ThumbsUp />
      <p>
        All done, now you are ready to register yourself <br /> on the iGig app!
      </p>
      <div className="iconsContainer">
        <a href="#">
          <img className="google" src={GoogleBadge} />
        </a>
        <a href="#">
          <AppleBadge className="apple" />
        </a>
      </div>
    </div>
  );
};
