import React from "react";
import "./styles.css";

export const NotFound = () => {
  return (
    <div className="notfound">
      <h1>404</h1>
      <p>Invitation not found</p>
    </div>
  );
};
