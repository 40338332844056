import React from "react";
import "./styles.css";
import axios from "axios";
import { Loader } from "../Loader";

interface IInputProps {
  info: {
    name: string;
    userId: string;
  };
  handleSuccess: () => void;
}

export const Input = ({ info, handleSuccess }: IInputProps) => {
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    error && setError("");

    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    setError("");
    setLoading(true);

    try {
      const regex =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;

      if (!regex.test(email)) throw new Error("Invalid email address");

      await axios.post(
        "https://igig.api.k8s.luby.me/user/pre-signin",
        {
          email,
          inviterId: info.userId,
        },
        {
          auth: {
            username: "myusername",
            password: "password123",
          },
        }
      );

      handleSuccess();
    } catch (error: any) {
      setError(
        "Something went wrong. Please verify your email address or try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="inputContainer">
      <p>{info.name} has invited you to iGig!</p>
      <p>Please specify your email address to be finish the invitation.</p>
      <input
        type="email"
        placeholder="Email"
        className="input"
        value={email}
        onChange={handleEmail}
      />
      {error && <p className="error">{error}</p>}
      <button onClick={handleSubmit} className="button">
        {loading ? <Loader /> : "Submit"}
      </button>
    </div>
  );
};
